import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../../../components/SEO';
import Layout from '../../../../layouts/index';

const PrivacyCB = props => (
  <Layout bodyClass="page-privacy">
    <SEO title="Privacy" />
    <div className="container">
      <div className="row">
        <header className="col-12">
          <h1>Privacy Policy</h1>
        </header>
      </div>

      <div className="row">
        <div className="col-12">
          <h3>General</h3>

          <p>
            This page is used to inform users regarding our policies about the collection, use, and
            disclosure of personal information when using our apps.
            <br />
            By using our apps, you agree to the terms in this policy. Any information collected is
            used for providing and improving our service. We will use your information as is
            described in this privacy policy.
          </p>

          <h3>Information Collection and Use</h3>
          <p>
            CalorieBalance is reading data stored in your HealthKit store. It does not require
            writing permissions and therefore can and will not alter your data in any way.
            <br />
            We do not store or share this information. Every calculation is done on your local phone
            without the need of sharing this data with others. We do not show ads. We do not collect
            any information regarding to you, your device or the use of the application. To be
            honest, we do not collect any usage data at all, and we don&rsquo;t plan to.
          </p>

          <p>
            If the app unexpectedly crashes, automatically created diagnostic data may be sent to
            the service, containing information like the time and date of the crash and your
            operating system version. This data is anonymous and will never be shared.
          </p>

          <h3>Links to Other Sites</h3>
          <p>
            This Service may contain links to external websites not created or operated by us. We
            strongly advise you to review the Privacy Policy of these websites. We have no control
            over and assume no responsibility for the content, privacy policies, or practices of any
            third-party sites or services.
          </p>

          <h3>Changes to This Privacy Policy</h3>
          <p>
            We may update our privacy policy from time to time. Any changes we make to our privacy
            policy in the future will be made available to you via the privacy policy, accessible on
            this website. Please check back frequently to see any updates or changes to our privacy
            policy.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to
{' '}
            <Link to="/contact">contact us</Link>
.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyCB;
